const blogsData = [
  {
    id: 24,
    url_slug: 'authentic-asian-street-food',
    author:'Joshua Walton',
    authorImgSmall:'https://memberapps.s3.ap-southeast-2.amazonaws.com/cut/co-founder.webp',
    authorImgLarge:'https://memberapps.s3.ap-southeast-2.amazonaws.com/cut/co-founder-blog.webp',
    authorDesignation:'Co-Founder of Catch U There',
    authorMessage:`Josh uses his travel experiences to innovate technology at Catch U There, creating intuitive solutions for travelers. When not advancing travel tech, he shares his knowledge, inspiring others to explore more.`,
    authorSocialLinks:{
      linkedIn:'https://www.linkedin.com/in/joshua-walton-1868a6222'
    },
    title:'Colombia',
    meta: [
      { charset: "UTF-8" },
      { name: "description", content: "Free Web tutorials" },
      { name: "keywords", content: "HTML, CSS, JavaScript" },
      { name: "author", content: "John Doe" },
      { name: "viewport", content: "width=device-width, initial-scale=1.0" }
    ],
    heading: "Authentic Asian Street Food: Exploring Cultural Richness and Diversity",
    cityName: "Pacific",
    location: "asia",
    readingTime:'3 Min Read',
    bannerImg: {
      title: "Authentic Asian Street Food: Exploring Cultural Richness and Diversity",
      altText: `Vibrant selection of skewered dishes at a Thai food market`,
      url_large: "https://memberapps.s3.ap-southeast-2.amazonaws.com/cut/food_market_skewers_large.webp",
      url_medium: "https://memberapps.s3.ap-southeast-2.amazonaws.com/cut/food_market_skewers_medium.webp",
      url_small: "https://memberapps.s3.ap-southeast-2.amazonaws.com/cut/food_market_skewers_small.webp",
      source: "© Subodh Agnihotri / Getty Images",
    },
    date: "01 April 2024",
    content: [
      {
        type:"intro-para",
        text: [
          { text: <span>Authentic Asian street food is a universe of flavours, each dish telling its own story. It's where tradition meets innovation on bustling streets across the continent. You'll learn about the rich diversity and cultural significance behind these mouth-watering treats that have captivated travellers and locals alike.</span> },
          { text: <span>We dive into iconic dishes from various countries, offering you a taste guide to navigate this culinary landscape. Additionally, we provide valuable advice for savouring authentic Asian street food in a manner that's both secure and considerate, ensuring your culinary journey is as genuine as possible.</span> },
          { text: <span>Concerned about health? We tackle common myths around cleanliness and nutritional value too. And there's more – insights into vendors' lives reveal the dedication behind every bite while exploring how tourism shapes local food scenes.</span> },
          { text: <span>Ready for a journey through Asia’s vibrant street food culture? Let’s start.</span> }
      ]
      
      },
      {
        type:"tableOfContents",
        text: [
          { text: <a href="#heading1">The Essence of Asian Street Food</a> },
          { text: <a href="#heading2">Iconic Street Foods Across Asia</a> },
          { text: <a href="#heading3">Street Food Etiquette and Tips</a> },
          { text: <a href="#heading4">The Health Aspect of Street Food</a> },
          { text: <a href="#heading5">Behind the Scenes of Street Food Vending</a> },
          { text: <a href="#heading6">The Impact of Tourism on Street Food Culture</a> },
          { text: <a href="#heading7">Fusion and Innovation in Street Food</a> },
          { text: <a href="#heading8">Preserving Tradition in the Face of Modernization</a> },
          { text: <a href="#heading9">Popular Asian Street Food Dishes</a> },
          { text: <a href="#heading10">FAQs in Relation to Authentic Asian Street Food</a> },
          { text: <a href="#heading11">Parting Words</a> }
      ]
      
      },
      {
        type:"paragraph",
        id:"heading1",
        heading:"The Essence of Asian Street Food",
        text: [
          { text: <span>Imagine walking down a bustling street in Asia, where the air is filled with tantalizing aromas that make your stomach rumble. This is the enchantment encapsulated in Asian street cuisine. Embarking on this journey isn't merely about quenching hunger; it's a sensory expedition, teasing and delighting the palate.</span> },
          
          { text: <b>Iconic Street Foods Across Asia:</b> },
          { text: <span>Each country in Asia offers its own unique twist on street food, creating a rich tapestry of flavours and dishes. Journeying from the fiery zest of Thailand's Som Tum to Japan's succulent Takoyaki, these culinary creations weave narratives of tradition and taste. They're crafted from recipes passed down through generations, each bite a piece of cultural heritage.</span> },
          { text: <span>In Vietnam, Pho stalls line every corner offering their steaming bowls at dawn while South Korea’s streets buzz with vendors selling Tteok-bokki – spicy rice cakes that pack a punch. Meanwhile, China surprises you with Jianbing - a kind of crispy crepe filled with anything from hoisin sauce to crisp wontons.</span> },
      
          { text: <b>Street Food Etiquette and Tips:</b> },
          { text: <span>Navigating the vibrant world of street food requires some know-how to get the most authentic experience. Always follow local eating customs – whether it's using chopsticks skilfully in Vietnam or mastering the art of eating with your hands in India.</span> },
          { text: <span>To find the best spots, <a target="_blank" href="https://www.eater.com/"> Eater</a> suggests looking for queues of locals or asking residents for recommendations because if there’s one truth universally acknowledged, it’s that locals always know where to go for good eats.</span> },
      
          { text: <b>The Health Aspect of Street Food:</b> },
          { text: <span>A common myth is that street food can't be healthy but think again. Many vendors use fresh ingredients bought daily from local markets which translates into nutritious meals packed full flavour without breaking your diet (or wallet).</span> },
          { text: <span>Beyond just being a tasty treat though understanding how these small-scale culinary entrepreneurs maintain hygiene can give insights on safe dining practices abroad—a crucial tip shared by CDC. Making informed choices ensures enjoyment doesn’t come at cost health wise.</span> }
      ]
      
      },
      {
        type:"paragraph",
        id:"heading2",
        heading:"Iconic Street Foods Across Asia",
        text: [
          { text: <span>When you think of Asian street food, your taste buds might start dancing at the thought of spicy, sweet, sour, and savoury flavours all mingling together. Embarking on this gastronomic journey propels you down vibrant lanes and serene paths, presenting morsels that offer a peek into the soul of diverse traditions.</span> },
      
          { text: <span>Diving deep into the essence of Asian street food means embracing its diversity. From the sizzling satays in Indonesia to Japan's delicate Takoyaki balls filled with bits of octopus - it’s more than just eating; it’s experiencing life on those streets. This rich tapestry is woven from generations-old recipes passed down like precious heirlooms.</span> },
      
          { text: <span>This journey also lets us explore how these dishes reflect their community's history and lifestyle. For instance, Vietnam’s pho is not just about broth or noodles but speaks volumes about resourcefulness and resilience amidst adversity.</span> },
      
          { text: <span>Navigating through Asia's street food scene requires some know-how to truly enjoy what it has to offer without any faux pas. Always have cash on hand as many vendors don’t accept cards. And remember: patience is key. Good things come to those who wait in line respectfully.</span> },
      
          { text: <span>Eating where locals eat often guarantees an authentic experience but keep an eye out for cleanliness too. Don't shy away from asking locals or fellow travellers for recommendations – they usually lead you right to gastronomic bliss.</span> },
      
          { text: <span>Embarking on this flavourful expedition across Asia invites us not only onto its streets but into its very soul – one delicious bite at a time.</span> }
      ]
      
      },
      {
        type:"image",
        source: "© desaart / Getty Images",
        altText:`A large pan filled with carrots, green beans, and corn cooking over an open fire.`,
        url:`https://memberapps.s3.ap-southeast-2.amazonaws.com/cut/vegetables_pan.webp`
      },
      {
        type:"paragraph",
        id:"heading3",
        heading:"Street Food Etiquette and Tips",
        text: [
          { text: <span><b>How to Spot the Best Stalls:</b> The golden rule? Look for crowds. A busy stall often means delicious, fresh food that locals trust. Moreover, immersing yourself in the bustling atmosphere of street dining offers a unique opportunity to experience the community's pulse.</span> },
      
          { text: <span>Another tip is to keep an eye out for stalls specializing in one dish—they're usually masters at it. Think about it: Would you rather eat from someone who makes fifty dishes okay or one dish spectacularly?</span> },
      
          { text: <span>If visuals are your thing, don't underestimate Instagram as a tool. Search location tags near you; chances are, there’s a drool-worthy feed showcasing where the action's hot.</span> },
      
          { text: <span><b>Safety First: Enjoying Without Worry:</b> No one wants their food adventure cut short by a tummy upset. To dodge this unwelcome guest, opt for stalls where there's a high turnover of food—this usually means ingredients are fresh because they're being snapped up quickly. Also, give preference to cooked-to-order dishes over those that have been sitting out.</span> },
      
          { text: <span>A handy tip from seasoned travellers: Carry your own utensils or use disposable ones provided by vendors.</span> }
      ]
      
      },
      {
        type:"paragraph",
        id:"heading4",
        heading:"The Health Aspect of Street Food",
        text: [
          { text: <span><b>When you think street food, "healthy" might not be the first word that pops into your head.</b> But here's a twist: it can actually be a treasure trove of nutritious eats if you know what to look for.</span> },
      
          { text: <span><b>Addressing the giant issue at hand,</b> we're diving into hygiene's critical role. Yes, we've all heard horror stories about street food gone wrong. But truth is, many vendors are meticulous about hygiene. They have to be; their business depends on it. A good rule of thumb? Look for stalls with long lines – popular spots often mean fresh ingredients and high turnover.</span> },
      
          { text: <span>Beyond safety concerns lies a world rich in nutrients - if you make smart choices. Asian street foods offer an array of dishes packed with vegetables, lean meats, and healthy fats like those found in nuts or avocados used in various sauces and toppings.</span> },
      
          { text: <span><b>Diving deeper into nutritional content doesn’t have to dampen your culinary adventure either;</b> opting for grilled items over fried ones or choosing brown rice as a base when available can significantly up your meal’s health factor without skimping on taste.</span> },
      
          { text: <span><b>In the end, enjoying street food comes down to making informed choices.</b> By keeping an eye out for cleanliness cues from vendors and leaning towards nutrient-dense options, when possible, you'll discover that indulging in this authentic experience can indeed go hand-in-hand with maintaining a balanced diet.</span> }
      ]
      
      },
      {
        type:"paragraph",
        id:"heading5",
        heading:"Behind the Scenes of Street Food Vending",
        text: [
          { text: <span><b>Vendors wake up before dawn,</b> source fresh ingredients, and prepare their stalls for a day that's as unpredictable as the weather. This isn't just about cooking; it's a blend of passion, perseverance, and unparalleled skill.</span> },
      
          { text: <span><b>Imagine juggling flaming pans while keeping an eye on simmering pots—</b> all in a space no bigger than your bathroom. For street food vendors, this is just another day at the "office". Expertly juggling the art of culinary multitasking, they adeptly toss noodles with one hand while simultaneously attending to patrons with the other.</span> },
      
          { text: <span><b>But what you don't see is the prep work:</b> slicing vegetables into perfect julienne strips or marinating meats overnight. Every dish has its secret recipe passed down through generations. And these aren’t secrets you can Google—they’re earned through years behind the stall.</span> }
      ]
      
      },
      {
        type:"key-takeaway",
        text:<span>Street food vending is a complex and skilled profession that combines passion, perseverance, and culinary expertise, alongside the preservation of traditional recipes and methods passed down through generations.</span>
      },
      {
        type:"paragraph",
        id:"heading6",
        heading:"The Impact of Tourism on Street Food Culture",
        text: [
          { text: <span><b>Imagine wandering through the bustling streets of Bangkok,</b> your senses alive with the aromas of sizzling satay and fresh papaya salad. Now picture this scene becoming increasingly common as tourists flock to Asia in search of authentic street food experiences. But what does this mean for local vendors and their traditional cuisines?</span> },
      
          { text: <span><b>Positive Impacts: A Global Stage for Local Delights:</b> Tourists often arrive with curiosity but leave behind a demand for more diverse dishes. Vendors, eager to please this new crowd, start tweaking their recipes or adding entirely new items to their menus. This isn't just about throwing in an extra chili or two; it’s about creating fusion foods that might never have existed otherwise. Think kimchi tacos or curry pizza - sounds odd, but boy does it work.</span> },
      
          { text: <span><b>This culinary crossover doesn’t stop at the menu board.</b> It influences how vendors prepare and present their dishes too.</span> },
      
          { text: <span><b>Challenges Faced by Vendors: Keeping It Real in a Changing World:</b> However, with great exposure comes great challenges. The surge in demand can lead to commercialization where quantity overshadows quality. Some vendors might cut corners or alter recipes to cater to tourist tastes rather than sticking to tradition.</span> },
      
          { text: <span>To tackle this issue head-on, <a target="_blank" href="https://www.slowfood.com/"> Slow Food movements</a> encourage supporting those who maintain authenticity despite growing tourist numbers. They remind us that at its heart, street food is about community and culture as much as it is about delicious eats.</span> },
      
          { text: <span><b>In essence,</b> while tourism can indeed transform local street food scenes—bringing both visibility and variety—it's crucial we support practices that preserve originality so these culinary treasures don't lose their soul amidst globalization’s appetites.</span> }
      ]
      
      },
      {
        type:"paragraph",
        id:"heading7",
        heading:"Fusion and Innovation in Street Food",
        text: [
          { text: <span><b>Street food transcends mere consumption,</b> offering a journey into the essence of culture where innovation melds with tradition. Imagine biting into a taco that dances with flavours from Japan or savouring a pizza topped with spicy Korean kimchi. Here, age-old customs blend with imaginative twists.</span> },
      
          { text: <span><b>The Rise of Fusion Flavors:</b> Today’s street food scene bursts at the seams with bold experiments. Globally, culinary artists are merging traditional dishes, crafting an entirely novel dining experience. Take, for instance, the Gua Bao Taco - a perfect marriage between Taiwanese steamed buns and Mexican fillings. It’s like attending an international party in your mouth.</span> },
      
          { text: <span><b>This trend isn’t just limited to high-end restaurants</b> but thrives on streets where authenticity mingles freely with new ideas. One reason behind this explosion of creativity could be global migration patterns which bring diverse cultures together, fostering an environment ripe for culinary experimentation.</span> },
      
          { text: <span><b>Innovative Cooking Techniques:</b> With ingredients journeying from the farthest reaches of Earth, there's a revolution in the methods and presentations of culinary creations. Street vendors are adopting techniques like sous-vide cooking to ensure perfectly tender meats every time or using liquid nitrogen for instant ice cream delights right before your eyes.</span> },
      
          { text: <span><b>Such innovations do more than offer novel experiences;</b> they elevate street food from quick bites to gourmet treats without losing its soulful essence – convenience combined with flavour-packed goodness that keeps you coming back for more.</span> },
      
          { text: <span><b>No matter where these trends take us next,</b> one thing remains clear: street food will continue evolving as long as there are hungry adventurers out there ready to explore new tastes on their plates.</span> }
      ]
      

      },
      {
        type:"image",
        source: "© Leung Cho Pan / Canva",
        altText:"A lively night market scene in Taipei, Taiwan, showcasing various food stalls and a bustling atmosphere.",
        url:"https://memberapps.s3.ap-southeast-2.amazonaws.com/cut/taipei_night_market.webp",
      },
      {
        type:"paragraph",
        id:"heading8",
        heading:"Preserving Tradition in the Face of Modernization",
        text: [
          { text: <span><b>The battle between tradition and modernity</b> is nowhere more apparent than in the bustling streets of Asia, where age-old street food stands face off against the encroaching tide of fast-food chains. In this epicurean clash of titans, not only tastes are at stake but also the very essence of cultural heritage and selfhood.</span> },
      
          { text: <span><b>The Essence of Asian Street Food:</b> Asian street food isn't just about sustenance; it's a cultural tapestry woven with flavors passed down through centuries. Every bite tells a story - whether it’s the smoky aroma of satay on charcoal grills or the comforting warmth of pho simmering over an open flame. Yet, this profound legacy is under siege by the relentless march of urban expansion and the quick tempo of modern life that leans towards ease rather than genuineness.</span> },
      
          { text: <span><b>To combat these pressures,</b> communities across Asia are banding together to protect their culinary legacy. In places like Bangkok and Singapore, local authorities have started recognizing street food vendors as integral parts of national identity by granting them designated spaces where they can thrive away from gentrifying forces.</span> },
      
          { text: <span><b>To truly appreciate what’s at stake here</b> requires understanding what makes each dish special. From Thailand’s Pad Thai to Japan’s Takoyaki balls—these aren’t merely snacks but stories on plates that carry whispers from ancestors who knew their ingredients intimately.</span> },
      
          { text: <span><b>It's crucial we recognize these dishes</b> for more than their flavour—they're cultural artifacts carrying forward traditions in every savoury or sweet note they hit.</span> }
      ]
      
      },
      {
        type:"paragraph",
        id:"heading9",
        heading:"Popular Asian street food dishes",
        text: [
          { text: <span><b>Asian street food</b> is like the continent's heartbeat, vibrant and pulsating with flavours that tell a thousand stories. It’s not just about filling your stomach; it’s an adventure for your taste buds, offering a glimpse into the culture and traditions of each place. From sizzling skewers in bustling night markets to sweet treats on quiet side streets, every dish has its own history.</span> },
      
          { text: <span><b>Diving into this culinary journey</b> means exploring corners you never knew existed and tasting things you can't pronounce (yet). Let's embark on this flavour-filled voyage together.</span> },
      
          { text: <span><b>Iconic Street Foods Across Asia:</b> Each country in Asia boasts its signature dishes that have gained international fame or remain hidden gems waiting to be discovered by the curious traveller. Here are some highlights:</span> },
      
          {text: <span>
            <ul>
              <li><b>Pad Thai, Thailand:</b> This stir-fried noodle delight combines tamarind juice, fish sauce, peanuts, and egg into one unforgettable dish.</li>
              <li><b>Takoyaki, Japan:</b> These octopus-filled dough balls topped with mayonnaise and bonito flakes will dance their way into your heart.</li>
              <li><b>Banh Mi, Vietnam:</b> A fusion marvel; French baguette meets Vietnamese flair with fillings ranging from grilled meats to pickled vegetables.</li>
              <li><b>Pani Puri/Golgappa/Puchka, India & Pakistan:</b> A thrilling burst of tamarind water packed in semolina spheres - a true explosion of flavours.</li>
            </ul>
          </span>}
          ]
      
      },
      {
        type:"keyTakeaway",
        text:<span>Asian street food represents the vibrant essence of the continent, offering an adventurous culinary journey that reflects the rich culture and traditions of each region. It's an exploration of unique flavors and historical dishes, ranging from internationally acclaimed specialties to lesser-known local treasures, inviting travelers to discover the stories and craftsmanship behind every bite.</span>
      },
      {
        type:"paragraph",
        id:"heading10",
        heading:"FAQs in Relation to Authentic Asian Street Food",
        text: [
          { text: <span><b>How can I find the best street food stalls in Asian cities?</b> Look for stalls with long lines, especially those frequented by locals. Social media and travel blogs can also offer up-to-date recommendations. Don't hesitate to ask locals for their favorite spots; many will be happy to share their insider knowledge.</span> },
      
          { text: <span><b>Is street food in Asia safe to eat?</b> Yes, street food in Asia can be safe and is an integral part of the culinary culture. To minimize risks, choose busy stalls ensuring high turnover and fresh ingredients. Observing food handling practices and opting for freshly cooked items can also help.</span> },
      
          { text: <span><b>What dietary restrictions can be accommodated with Asian street food?</b> Many Asian cuisines offer vegetarian, vegan, and gluten-free options. Dishes like vegetable stir-fries, tofu-based snacks, and rice or noodle dishes without meat can cater to various dietary needs. Always communicate your dietary restrictions clearly, as some dishes may contain hidden ingredients like fish sauce or shrimp paste.</span> },
      
          { text: <span><b>How do I pay for street food in Asia?</b> Cash is king at most street food stalls. Small denominations are preferred, as making change for large bills can sometimes be a challenge for vendors. Some cities may have vendors who accept digital payments, but it's less common.</span> },
      
          { text: <span><b>Can I get food allergies accommodated when eating street food in Asia?</b> While it's possible, it can be challenging due to language barriers and the use of common allergens like peanuts, soy, and shellfish in Asian cooking. Always carry allergy cards written in the local language to communicate your allergies clearly to vendors.</span> },
      
          { text: <span><b>What are some must-try beverages to accompany street food in Asia?</b> Tea, both iced and hot, is a staple across Asia and varies by region. Fresh coconut water, fruit juices like mango and sugarcane juice, and local specialties such as Vietnamese coffee, Thai iced tea, and sugarcane juice in various countries are excellent refreshments to accompany your meals.</span> },
      
          { text: <span><b>Are there any etiquette tips for eating street food in Asia?</b> Yes, observing local customs is important. For example, it's common to eat with chopsticks or your right hand in many Asian cultures. Be mindful of local dining etiquettes, such as removing your shoes when sitting on floor mats, and always thank the vendor after your meal.</span> },
      
          { text: <span><b>How can I explore street food sustainably and responsibly?</b> Supporting small, local vendors, avoiding single-use plastics by carrying your own utensils and containers, and minimizing food waste by ordering mindfully are ways to enjoy street food sustainably. Additionally, choosing vendors who use local and fresh ingredients contributes to responsible travel.</span> },
      
          { text: <span><b>What are 5 must-try street foods in Japan?</b></span> },
          { text: <span>1. Takoyaki: Dough balls filled with octopus, green onion, and tempura scraps, cooked in a molded pan and typically topped with mayonnaise, takoyaki sauce, green laver (aonori), and bonito flakes.</span> },
          { text: <span>2. Okonomiyaki: A savory pancake made from a batter mixed with cabbage and various other ingredients such as seafood, pork, and wasabi, grilled and often topped with okonomiyaki sauce, mayonnaise, green laver, and bonito flakes.</span> },
          { text: <span>3. Yakitori: Skewered chicken, grilled over charcoal and seasoned with salt or tare sauce. Yakitori stalls are a common sight in Japanese festivals and markets.</span> },
          { text: <span>4. Ramen: While commonly found in restaurants, street-side ramen stalls serve this popular noodle soup with various broths, noodles, and toppings, embodying the essence of quick and comforting street food.</span> },
          { text: <span>5. Onigiri: Rice balls filled with flavorful ingredients like pickled plum (umeboshi), salmon, or tuna, wrapped in seaweed. They are a convenient and portable snack, often found at street markets and convenience stores.</span> },
      
          { text: <span><b>What are 5 must-try street foods in Thailand?</b></span> },
          { text: <span>1. Pad Thai: A stir-fried noodle dish, served with lime wedges and often topped with ground peanuts, is perhaps the most iconic Thai street food, known for its perfect balance of sweetness, sourness, and saltiness.</span> },
          { text: <span>2. Som Tum: Spicy green papaya salad, made with shredded unripe papaya, tomatoes, carrots, peanuts, dried shrimp, and plenty of chillies, pounded in a mortar and pestle.</span> },
          { text: <span>3. Mango Sticky Rice (Khao Niew Mamuang): A beloved sweet treat made of glutinous rice, fresh mango slices, and coconut milk, showcasing the simplicity and sweetness of Thai desserts.</span> },
          { text: <span>4. Grilled Pork Skewers (Moo Ping): Marinated pork skewers, grilled over charcoal, are a common and delicious find in Thai markets, often served with sticky rice.</span> },
          { text: <span>5. Tom Yum Goong: This spicy and sour shrimp soup, flavored with lemongrass, kaffir lime leaves, galangal, and chillies, can be found in many street food stalls across Thailand, offering a taste of the country's rich flavor profile.</span> },
      
          { text: <span><b>What are 5 must-try street foods in South Korea?</b></span> },
          { text: <span>1. Tteokbokki: Spicy stir-fried rice cakes, a popular Korean street snack that's both chewy and spicy, often combined with fish cakes and scallions in a sweet and spicy sauce.</span> },
          { text: <span>2. Kimchi Jeon: A kimchi pancake, made by frying a batter mixed with chopped kimchi, creating a savory, tangy, and crispy treat.</span> },
          { text: <span>3. Odeng: Fish cake skewers served in a warm broth, a comforting street food item especially popular in colder months.</span> },
          { text: <span>4. Hotteok: Sweet syrup-filled pancakes, a winter favorite that offers a hot, gooey, and sweet experience with every bite.</span> },
          { text: <span>5. Bungeoppang: Fish-shaped pastries filled with sweet red bean paste, a popular snack during the winter season, known for its crispy outside and soft, sweet filling.</span> },

          { text: <span><b>What are 5 must-try street foods in India?</b></span> },
          { text: <span>1. Pani Puri: Hollow, fried crisp spheres filled with a mixture of flavored water (pani), tamarind chutney, chili, chaat masala, potato, onion, and chickpeas.</span> },
          { text: <span>2. Samosa: A fried or baked pastry with a savory filling, such as spiced potatoes, onions, peas, lentils, and sometimes ground lamb, ground beef, or ground chicken.</span> },
          { text: <span>3. Vada Pav: A spicy potato filling deep-fried in chickpea flour, served inside a bread bun (pav) slathered with various chutneys and spices, often called the Indian burger.</span> },
          { text: <span>4. Dosa: A fermented crepe made from rice batter and black lentils, served with a variety of chutneys and sambar (lentil-based stew).</span> },
          { text: <span>5. Chaat: A term describing savory snacks, typically served at roadside tracks from stalls or carts, with varieties including bhel puri, sev puri, and dahi puri, known for their mix of spicy, tangy, and sweet flavors.</span> },

          { text: <span><b>What are 5 must-try street foods in Vietnam?</b></span> },
          { text: <span>1. Banh Mi: A Vietnamese sandwich that combines a crispy baguette with savory ingredients such as pâté, mayonnaise, pickled vegetables, cilantro, and various meats or tofu.</span> },
          { text: <span>2. Pho: Vietnam's famous noodle soup, typically made with beef (pho bo) or chicken (pho ga), rice noodles, and a flavorful broth, garnished with herbs and lime.</span> },
          { text: <span>3. Banh Xeo: Crispy crepes made from rice flour, water, and turmeric powder, filled with shrimp, pork, bean sprouts, and served with a side of fresh herbs and fish sauce for dipping.</span> },
          { text: <span>4. Goi Cuon: Fresh spring rolls, a lighter alternative to fried spring rolls, filled with greens, coriander, minced pork, shrimp, and rice vermicelli, wrapped in rice paper.</span>},
          { text: <span>5. Cao Lau: A regional dish from Hoi An, consisting of thick rice noodles, pork, fresh greens, and herbs, known for its unique taste and texture.</span>},
          { text: <span><b>What are 5 must-try street foods in China?</b></span>},
          {
            text: <span>Jianbing: A popular breakfast item, this savory crepe is made with wheat and grain flour, eggs, and topped with green onions, hoisin sauce, and crispy wonton crackers.</span>
          },
          {
            text: <span>Baozi: Steamed buns filled with various ingredients, such as minced pork, vegetables, or sweet bean paste, offering a soft, fluffy texture and a flavorful filling.</span>
          },
          {
            text: <span>Rou Jia Mo: Often referred to as the Chinese hamburger, this street food consists of chopped meat (usually pork) stewed with spices and stuffed into a flatbread.</span>
          },
          {
            text: <span>Chuan'r (Skewered Meat): Marinated meat (commonly lamb or chicken) skewered and grilled over charcoal, seasoned with cumin, chili, and other spices, showcasing the influence of Chinese Muslim cuisine.</span>
          },
          {
            text: <span>Xi'an Stuffed Pancakes: Originating from Xi'an, these crispy, stuffed pancakes are filled with various ingredients like minced pork, vegetables, and spices, offering a satisfying crunch and rich flavors.</span>
          },
          {
            text: <span>What are 5 must-try street foods in Malaysia?</span>
          },
          {
            text: <span>Nasi Lemak: Considered the national dish, it's traditionally served as a fragrant rice dish cooked in coconut milk and pandan leaf, accompanied by fried crispy anchovies, peanuts, boiled egg, cucumber, and spicy sambal.</span>
          },
          {
            text: <span>Satay: Skewered and grilled meat served with peanut sauce, rice cakes, and a small serving of a cucumber-onion salad, highlighting the multicultural influences in Malaysian cuisine.</span>
          },
          {
            text: <span>Roti Canai: A type of Indian-influenced flatbread that's crispy on the outside and soft on the inside, served with dhal (lentil curry) or other types of curry for dipping.</span>
          },
          {
            text: <span>Char Kway Teow: A popular noodle dish stir-fried with shrimp, bloody cockles, Chinese lap cheong (sausage), eggs, bean sprouts, and chives in a mix of soy sauce.</span>
          },
          {
            text: <span>Apam Balik: A thick, fluffy pancake with a crispy edge, filled with a mixture of sugar, corn, and crushed peanuts, offering a sweet and savory taste that's hard to resist.</span>
          },
          {
            text: <span><b>What are 5 must-try street foods in Indonesia?</b></span>
          },
          {
            text: <span>Martabak: A versatile dish that can be savory or sweet. The savory version is like a stuffed pancake with fillings such as eggs, meat, and spices, while the sweet version is filled with chocolate, cheese, or peanuts.</span>
          },
          {
            text: <span>Satay: Similar to Malaysia, Indonesian satay is a popular street food consisting of skewered and grilled meat, served with various sauces, the most famous being peanut sauce.</span>
          },
          {
            text: <span>Nasi Goreng: Indonesian fried rice, typically spiced with kecap manis (sweet soy sauce), shallots, garlic, tamarind, and chili, mixed with egg, chicken, or prawns, and served with cucumber slices and krupuk (crackers).</span>
          },
          {
            text: <span>Bakso: A meatball noodle soup, with meatballs typically made from beef or chicken, served in a savory broth with noodles, tofu, and sometimes a hard-boiled egg.</span>
          },
          {
            text: <span>Gado-Gado: A traditional Indonesian salad made from a mix of boiled vegetables, hard-boiled eggs, fried tofu, and tempeh, topped with a peanut sauce dressing.</span>
          },
        ]
      
      },
      {
        type:"paragraph",
        id:"heading11",
        heading:"Parting words",
        text: [
          { text: <span>Diving into authentic Asian street food opens up a world where every bite is a story. Exploring this vivid culinary landscape, you've woven through the rich palette of tastes, grasped the craftsmanship in every meal, and mastered moving within this lively setting.</span> },
      
          { text: <span>Keep in mind, honouring the traditions and adhering to the local customs is crucial. Safety first but don't shy away from adventure. Hold onto the old as you reach for the new.</span> },
      
          { text: <span>From bustling stalls to quiet corners, it's clear: authentic Asian street food is not just about sustenance; it's an experience that connects us all. Let these insights guide your taste adventures.</span> },
      
          { text: <span>So go ahead, embrace the vibrant world of authentic Asian street food with confidence and curiosity. Because every bite tells a story worth savouring.</span> }
      ]
      
      },
    ]
  }
];

export default blogsData;