import { React, useRef, useState } from "react";
import { styled, alpha } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import InputBase from "@mui/material/InputBase";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import "./scss/Navbar.css";
import { Link } from "react-router-dom";
import ProfileDrawer from "./Profiledrawer";
import blogsData from "../../pages/Blogs/blogsData";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

export default function SearchAppBar(props) {
  const { currentPage } = props;
  const [open, setOpen] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const anchorRef = useRef(null);

  // menu code

  const [isOpen, setIsOpen] = useState(false);

  const handleMouseEnter = () => {
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    setIsOpen(false);
  };

  // find locations for blogs
  const getUniqueLocations = (blogs) => {
    const locations = blogs.map((blog) => blog.location);
    return [...new Set(locations)];
  };

  const uniqueLocations = getUniqueLocations(blogsData);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const [token, setToken] = useState(
    window.sessionStorage.getItem("token") != ""
      ? ""
      : window.sessionStorage.getItem("token")
  );

  const signout = () => {
    window.sessionStorage.clear();

    setToken(null);
    console.log(sessionStorage.getItem("token"));
  };
  // const handleClick = (event) => {
  //   setAnchorEl(anchorEl ? null : event.currentTarget);
  // };

  // const open = Boolean(anchorEl);
  // const id = open ? 'simple-popper' : undefined;
  // console.log(window.sessionStorage.getItem("token"))

  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: "transparent",
        boxShadow: "none",
        padding: { xs: "0 5vw", md: "0 0 0 5vw", lg: "0 0 0 7vw" },
      }}
    >
      <Toolbar style={{ padding: "0" }}>
        <Grid container sx={{ display: "flex", alignItems: "center" }}>
          <Grid item lg={2} sm={8} xs={8}>
            <Link
              className="nav-link active"
              to="/"
              style={{ display: "flex", alignItems: "center" }}
            >
                <img
                src={currentPage === "landing-page" ? "/assets/images/logofiled.svg" : "/assets/images/logo.jpg"}
                loading="eager"
                width="220px"
                height="100%"
                alt="Catch U There logo"
              />
            </Link>
          </Grid>
          <Grid
            item
            sx={{
              display: { xs: "flex", sm: "flex", md: "flex", lg: "none" },
            }}
            xs={4}
            sm={4}
            lg={0}
            marginLeft={{ xs: "0", md: "0", lg: "0" }}
            justifyContent="end"
          >
            <ProfileDrawer currentPage={currentPage} />
            {/* <MainNavDrawer /> */}
          </Grid>

          {currentPage === "landing-page" ||
          currentPage === "about-us" ||
          currentPage === "privacy-policy" ||
          currentPage == "cookies" ? (
            <Grid
              item
              lg={8}
              sm={8}
              sx={{
                display: { xs: "none", sm: "none", md: "none", lg: "flex" },
                flexDirection: "row",
              }}
            >
              <ul className="nav-links">
                {/* <Link
                    style={{
                      fontWeight: "700",
                      marginRight: "30px",
                      color: "#1E1D4C",
                    }}
                    to="/explore"
                  > */}
                <li>
                  <div
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    style={{ position: "relative", display: "inline-block" }}
                  >
                    <Button
                      sx={{
                        fontWeight: "700",
                        fontSize: "16px",
                        color: "#1E1D4C",
                        margin: "0 30px",
                        textTransform: "none",
                      }}
                    >
                      Explore
                    </Button>

                    {isOpen && (
                      <div className="menu">
                        <Link to="/explore">
                          <div className="menu-item">All Blogs</div>
                        </Link>
                        {uniqueLocations
                          .filter(
                            (location) => location !== "general travel tips"
                          )
                          .map((location, index) => (
                            <Link
                              key={index}
                              to={`/explore/${location
                                .toLowerCase()
                                .replace(/\s+/g, "-")}`}
                            >
                              <div className="menu-item">{location}</div>
                            </Link>
                          ))}
                        <Link to="/explore/general-travel-tips">
                          <div className="menu-item">General Travel Tips</div>
                        </Link>
                      </div>
                    )}
                  </div>
                </li>

                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Link
                    style={{
                      fontWeight: "700",
                      color: "#1E1D4C",
                    }}
                    to="/about-us"
                  >
                    
                    About us
                  </Link>
                </li>
              </ul>
            </Grid>
          ) : (
            <Grid
              item
              lg={9}
              sm={8}
              sx={{
                display: { xs: "none", sm: "none", md: "none", lg: "flex" },
                flexDirection: "row",
              }}
            >
              <ul className="nav-links">
                {[
                  "cookies",
                  "privacy-policy",
                  "contact-us",
                  "about-us",
                  "explore",
                  "explore-blogs",
                  "blogs",
                ].includes(currentPage) ? (
                  <>
                    <li>
                      <div
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        style={{
                          position: "relative",
                          display: "inline-block",
                        }}
                      >
                        <Button
                          sx={{
                            fontWeight: "700",
                            fontSize: "16px",
                            color: "#ffffff",
                            margin: "0 30px",
                            textTransform: "none",
                          }}
                        >
                          Explore
                        </Button>

                        {isOpen && (
                          <div className="menu">
                            <Link to="/explore">
                              <div className="menu-item">All Blogs</div>
                            </Link>
                            {uniqueLocations
                              .filter(
                                (location) => location !== "general travel tips"
                              )
                              .map((location, index) => (
                                <Link
                                  key={index}
                                  to={`/explore/${location
                                    .toLowerCase()
                                    .replace(/\s+/g, "-")}`}
                                >
                                  <div className="menu-item">{location}</div>
                                </Link>
                              ))}
                            <Link to="/explore/general-travel-tips">
                              <div className="menu-item">
                                General Travel Tips
                              </div>
                            </Link>
                          </div>
                        )}
                      </div>
                    </li>
                    <li
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Link
                        style={{
                          fontWeight: "700",
                          color: "#ffffff",
                          fontSize: "16px",
                        }}
                        to="/about-us"
                      >
                        About us
                      </Link>
                    </li>
                  </>
                ) : (
                  <>
                    <Link className="nav-link links" to="/comingsoon">
                      <li>Trip Planer</li>
                    </Link>
                    <Link className="nav-link links" to="/flights">
                      <li>Flights</li>
                    </Link>
                    <Link className="nav-link links" to="/hotelslist">
                      <li>Hotels</li>
                    </Link>
                    <Link className="nav-link links" to="/comingsoon">
                      <li>Car Hire</li>
                    </Link>
                  </>
                )}
              </ul>
            </Grid>
          )}

          {/* <Grid item xs={3} lg={1} sm={2} sx={{ paddingTop: "1%", display: "flex", justifyContent: "start", alignItems: "baseline", justifyContent: "end" }}>
              <SearchIcon sx={{ fontSize: 28, cursor: "pointer", display: { xs: "none", sm: "block" } }} />
              <Grid item xs={4} lg={3} sm={2} sx={{ display: "flex" }}>
                <IconButton ref={anchorRef} onClick={handleToggle}>
                <PersonOutlineIcon sx={{ color:'white', fontSize: 28, cursor: "pointer", margin: "auto" }} type="button" />
                </IconButton>

              </Grid>

              <Popper open={open} anchorEl={anchorRef.current} >
                <ClickAwayListener onClickAway={handleClose}>
                <Box sx={{ padding: '0 8px', borderRadius: "0.4rem", color: " white", marginTop: "2%"}} className="useroptions">
                  {window.sessionStorage.getItem("token") == null ?
                    <>
                    <Link to="/signin"><Button variant="contained" sx={{ color: "#3546AB", width: "100%", backgroundColor: "white" }}>Sign In &nbsp; <Login /></Button></Link>
                    <Link to="/signup"><Button variant="contained" sx={{ color: "white", width: "100%", backgroundColor: "#3546AB", border: '1px solid #3546AB !important' }}>Sign Up &nbsp; <PersonAddIcon /></Button></Link>
                    </>
                    :
                    <>
                      <Button variant="contained" sx={{ width: "100%", backgroundColor: "white", color: "#3546AB" }} onClick={signout}>LogOut</Button>
                    </>
                  }
                </Box>
                </ClickAwayListener> */}
          {/* <Box sx={{ padding: '0 8px', borderRadius: "0.4rem", fontWeight: 600, fontSize:'15px', display:'flex', justifyContent:'center', gap: 1, marginTop: "2%"}} className="useroptions">
                  {window.sessionStorage.getItem("token") == null ?
                    <>
                    <Link to="/signin"><Button variant="contained" sx={{ color: "#3546AB", minWidth:'0px', width:'40px', height:'35px', display:'block', backgroundColor: "white", padding:'0', borderRadius:'50%' }}> <Login /> <Typography fontSize="12px" >Sign In</Typography></Button></Link>
                    <Link to="/signup"><Button variant="contained" sx={{ color: "white", minWidth:'0px', width:'40px', height:'35px', display:'block', backgroundColor: "#3546AB", padding:'0', borderRadius:'50%', border: '1px solid #3546AB !important' }}><PersonAddIcon />Sign Up</Button></Link>
                    </>
                    :
                    <>
                      <Button variant="contained" sx={{ width: "100%", backgroundColor: "white", color: "#3546AB" }} onClick={signout}>LogOut</Button>
                    </>
                  }
                </Box> */}
          {/* </Popper> */}

          {/* <Search>
            <SearchIconWrapper>
              
            </SearchIconWrapper>
            <StyledInputBase sx={{display:{xs:"none",sm:"block"}}}
              placeholder="Search…"
              inputProps={{ 'aria-label': 'search' }}
            />
          </Search> */}

          {/* </Grid> */}
        </Grid>
      </Toolbar>
    </AppBar>
  );
}
