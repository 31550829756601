import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Navbar from "../../components/ui-components/Navbar";
import Footer from "../../components/ui-components/Footer";
import ExploreCard from "./ExploreCard";
import blogsData from "../Blogs/blogsData";

export default function ExploreBlogs() {
  const params = useParams();
  const { locationid } = params;
  const selectedCountryBlogs = blogsData.filter(
    (b) => b.location == locationid.replaceAll('-',' ')
  );

  const [displayCount, setDisplayCount] = useState(15);
  const loadMore = () => {
    setDisplayCount(displayCount + 15); // Increase the count by 10 when "Load More" is clicked
  };
  window.addEventListener("scroll", () => {
    // Check if user has scrolled to the bottom
    if (
      window.innerHeight + window.scrollY >= document.body.offsetHeight &&
      displayCount < blogsData.length
    ) {
      // Fetch more data
      loadMore();
    }
  });

  return (
    <>
      <Grid
        item
        xs={12}
        sx={{
          backgroundImage: `url(/assets/images/litineraryback.webp)`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <Navbar currentPage="explore-blogs" />
      </Grid>

      <Box display="flex" justifyContent="center">
        <Box flexGrow="1" padding="0 5vw" maxWidth="1536px">
          <Typography
            color="#2B2C34"
            fontSize="24px"
            fontWeight="700"
            textTransform="capitalize"
            display="flex"
            justifyContent={{ lg: "space-between", xs: "center" }}
            padding={{
              xs: "5vh 0 2vh 0",
              md: "5vh 0 2vh 0",
              lg: "5vh 5vw 2vh 4vw",
            }}
          >
            {locationid.replaceAll('-',' ')}
          </Typography>
          <Box
            padding="0 0 5vh 0"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Grid container display="flex" justifyContent="left">
              {locationid === "all"
                ? blogsData.slice(0, displayCount).map((item) => {
                    return <ExploreCard item={item} />;
                  })
                : selectedCountryBlogs.slice(0, displayCount).map((item) => {
                    return <ExploreCard item={item} />;
                  })}
            </Grid>
          </Box>
        </Box>
      </Box>
      <Footer />
    </>
  );
}
