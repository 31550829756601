import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { Helmet } from "react-helmet";
import './blogs.css';

export default function BlogsComponent({ blogs }) {
  return (
    <>
      <Helmet>
        <title>{blogs.title}</title>
        <meta charSet="UTF-8" />
        <link rel="preload" href="https://memberapps.s3.ap-southeast-2.amazonaws.com/cut/food_market_skewers_medium.webp" as="image" />
        <link rel="preload" href="https://memberapps.s3.ap-southeast-2.amazonaws.com/cut/food_market_skewers_small.webp" as="image" /> 
      </Helmet>        
      <Box padding="0 10vw">
        <Typography
          padding={{ xs: "32px 0", md: "32px 5vw" }}
          variant="h3"
          fontWeight="600"
          fontSize={{ xs: "36px", lg: "45px" }}
          textAlign={{ xs: "left", md: "center" }}
        >
          {blogs.heading}
        </Typography>
        <Box display="flex" flexDirection="column" justifyContent="center">
          {/* <Typography
            color="black"
            padding="2vh 0"
            fontWeight="400"
            variant="subtitle"
          >
            {blogs.cityName} / {blogs.location}
          </Typography> */}
          {/* <picture
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <source
              media="(min-width:992px)"
              srcSet={blogs.bannerImg.url_medium}
              width="1020"
              height="529"
            />
            <source
              media="(min-width:768px)"
              srcSet={blogs.bannerImg.url_medium}
              width="650"
              height="370"
            />
            <img
              src={blogs.bannerImg.url_small}
              alt={blogs.bannerImg.altText}
              title={blogs.bannerImg.title}
              width="330"
              height="165"
            />
          </picture> */}
          <Typography
            color="#121416"
            fontSize={{ xs: "10px", md: "14px", lg: "16px" }}
            lineHeight="27px"
            fontFamily="Inter"
            textAlign="center"
          >
            {blogs.bannerImg.source}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="space-between" padding={{xs:'5vh 0 2vh', md:"5vh 0"}}>
          <Box display="flex" gap={2} alignItems="center">
            <Box
              borderRadius="50%"
              overflow="hidden"
              width="70px"
              height="70px"
            >
              {blogs.authorImgSmall ? (
                <img
                  src={blogs.authorImgSmall}
                  alt="Image of Joshua, co-founder, dressed in a blue jacket and white shirt"
                  width="70px"
                  height="70px"
                  loading="lazy"
                />
              ) : (
                <img
                  src="/assets/images/userimg.png"
                  alt="The image of blog's author"
                  width="70px"
                  height="70px"
                    loading="lazy"
                />
              )}
            </Box>
            <Box display="flex" flexDirection="column">
              <Typography fontSize="18px">{blogs.author}</Typography>
              <Typography variant="subtitle" fontSize="14px">
                {blogs.date}
              </Typography>
            </Box>
          </Box>
        </Box>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Box
            padding={{ xs: "0px", md: "32px 5vw" }}
            maxWidth={{ xs: "600px", md: "600px", lg: "600px", xl: "700px" }}
          >
            {blogs.content.map((item, index) => {
              switch (item.type) {
                case "intro-para":
                  return item.text.map((item) => {
                    return (
                      <Typography
                        className="text-readability"
                        padding="0 0 2em"
                        color="#121416"
                        fontSize="16px"
                      >
                        {item.text}
                      </Typography>
                    );
                  });
                case "tableOfContents":
                  return (
                    <>
                      <Typography
                        className="text-readability"
                        padding="2vh 0"
                        fontFamily="Inter"
                        fontWeight="600"
                        color="#121416"
                        fontSize="28px"
                      >
                        Table of Contents
                      </Typography>
                      <ul style={{ paddingBottom: "23px" }}>
                        {item.text.map((item) => {
                          return (
                            <li>
                              <Typography
                                sx={{
                                  textDecoration: "underline",
                                  textDecorationColor: "#3546ab",
                                  padding: "5px 0",
                                  wordSpacing: "0.16rem",
                                }}
                              >
                                {item.text}
                              </Typography>
                            </li>
                          );
                        })}
                      </ul>
                    </>
                  );
                case "paragraph":
                  return (
                    <>
                      <h2
                        id={item.id}
                        className="text-readability"
                        style={{ padding: "0 0 1vh" }}
                      >
                        {item.heading}
                      </h2>
                      {item.text.map((item) => {
                        return (
                          <Typography
                            className="text-readability"
                            padding="0 0 23px"
                            variant="body1"
                          >
                            {item.text}
                          </Typography>
                        );
                      })}
                    </>
                  );
                case "image":
                  return (
                    <Box padding="32px 0 0">
                      <picture
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <source
                          media="(min-width:992px)"
                          srcSet={item?.url}
                          width="700"
                          height="420"
                        />
                        <source
                          media="(min-width:768px)"
                          srcSet={item?.url}
                          width="600"
                          height="270"
                        />
                        <img
                          src={item?.url}
                          alt={item?.altText}
                          width="330"
                          height="165"
                          loading="lazy"
                        />
                      </picture>

                      <Typography
                        color="#121416"
                        fontSize={{ xs: "10px", md: "14px", lg: "16px" }}
                        lineHeight="27px"
                        textAlign='center'
                        fontFamily="Inter"
                      >
                        {item?.source}
                      </Typography>
                    </Box>
                  );
                case "key-takeaway":
                  return (
                    <Box
                      bgcolor="#f0f8ff"
                      padding="30px 30px"
                      margin="32px 0 50px"
                    >
                      <Typography
                        fontSize="18px"
                        fontWeight="600"
                        padding="0 0 1vh"
                      >
                        Key Takeaway:
                      </Typography>
                      <Typography
                        fontSize="18px"
                        fontWeight="400"
                        lineHeight="38px"
                      >
                        {item.text}
                      </Typography>
                    </Box>
                  );
                default:
                  break;
              }
            })}
          </Box>
        </div>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          padding={{ xs: "0 2vw 10vh", md: "0 2vw 5vh", lg:'0 10vw 10vh' }}
        >
          <Typography
            color="#6373CF"
            fontSize="16px"
            fontWeight="600"
            fontFamily="Inter"
            pb="5vh"
          >
            MEET THE AUTHOR
          </Typography>
          <Grid container borderRadius="30px" bgcolor="#F3F5F6">
            <Grid item xs={12} md={4} padding={{ xs: "0 2vw 5vh", md: "0" }}>
              {/* <img
                src={blogs.authorImgLarge || "/assets/images/userimg.png"}
                alt={
                  blogs.authorImgLarge
                    ? "Image of Joshua, co-founder, dressed in a blue jacket and white shirt."
                    : "Image of the author of this blog."
                }
                style={{
                  maxWidth: "100%",
                  width: "100%",
                  height: "auto",
                  display: "block",
                  margin: "auto",
                  borderRadius: "30px",
                }}
                loading="lazy"
              /> */}
              <picture style={{display:'flex', justifyContent:'center'}}>
            <source media="(min-width:992px)" srcSet={blogs.authorImgLarge || "/assets/images/userimg.png"} width='325' height='419'/>
            <source media="(min-width:768px)" srcSet={blogs.authorImgLarge || "/assets/images/userimg.png"} width='250' height='322'/>
            <img
              src={blogs.authorImgLarge || "/assets/images/userimg.png"}
              alt={
                blogs.authorImgLarge
                  ? "Image of Joshua, co-founder, dressed in a blue jacket and white shirt."
                  : "Image of the author of this blog."
              }
              style={{
                borderRadius:'30px'
              }}
              width='311'
              height='400'
              loading="lazy"
            />
          </picture>
            </Grid>
            <Grid item xs={12} md={8}>
              <Box
                padding="0 50px"
                height="100%"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                gap={1}
              >
                <Typography
                  color="#170F49"
                  fontSize={{xs:'20px', md:'26px', lg:"34px"}}
                  fontWeight="700"
                  fontFamily="Inter"
                  textAlign={{ xs: "center", md: "left" }}
                >
                  {blogs.author}
                </Typography>
                <Typography
                  color="#6373CF"
                  fontSize={{xs:'14px', md:'14px', lg:"18px"}}
                  fontWeight="600"
                  fontFamily="Inter"
                  textAlign={{ xs: "center", md: "left" }}
                >
                  {blogs.authorDesignation}
                </Typography>
                <Typography
                  color="#6F6C90"
                  fontSize={{xs:'14px', md:'14px', lg:"18px"}}
                  fontWeight="400"
                  fontFamily="Inter"
                  textAlign={{ xs: "center", md: "left" }}
                >
                  {blogs.authorMessage}
                </Typography>
                <Box
                  display="flex"
                  justifyContent={{ xs: "center", md: "start" }}
                  sx={{ translate: { xs: "0", md: "-10px 0" } }}
                >
                  <IconButton
                    href={blogs.authorSocialLinks?.linkedIn}
                    aria-label="link to Author's linked"
                  >
                    <LinkedInIcon fontSize="large" />
                  </IconButton>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
}
