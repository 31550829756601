import React from 'react';
import ReactDOM from 'react-dom/client';
//import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import CookieConsent from 'react-cookie-consent';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <CookieConsent
        location="bottom"
        enableDeclineButton
        flipButtons
        buttonText="Allow Cookies"
        declineButtonText="Reject Cookies"
        cookieName="myAwesomeCookieConsent"
        style={{ background: "#ffffff", color:'#181E4B', boxShadow: '0 0 5px #888888'}}
        buttonStyle={{padding:'8px 18px', color: "#0A172A", backgroundColor:'#F4B63E', fontSize: "16px", borderRadius:'8px', fontWeight:'600' }}
        declineButtonStyle={{padding:'8px 18px', color: "#ffffff", backgroundColor:'#A41919', fontSize: "16px", borderRadius:'8px', fontWeight:'600' }}
        expires={150}
      >
        <p style={{color: 'rgb(24, 30, 75)', fontFamily:'Inter', fontWeight: 600, margin:'0'}}>This Website Uses Cookies To Enhance The User Experience.{" "}
        <a href="/cookie" aria-label='link to cookies page' style={{ color: "#181E4B", textDecoration:'underline'}}>Learn more about cookies</a></p>
      </CookieConsent>
    <App />

  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
