//import './App.css';
import { Contexts } from './vendors/Contexts';
// import HomePage from './pages/Home Page/HomePage';
// import Signup from './pages/Auths/Signup';
// import Signin from './pages/Auths/Signin';
// import Forgotpassword from './pages/Auths/Forgotpassword';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
// import Itinerary from './pages/Iitinerary/Iitinerary';
// import AuthsContext from './pages/Auths/AuthsContext';
// import Resetpassword from './pages/Auths/Restpassword';
import Privacy from './pages/policies/Privacy';
import Cookies from './pages/policies/Cookies';
// import ConfirmEmail from './pages/Email Confirmation/ConfirmEmail';
// import VerifyEmail from './pages/Email Confirmation/VerifyEmail';
// import Verificationforrest from './pages/Auths/Verificationforrest';
// import Session from './pages/Sessions';
// import IitineraryContext from './pages/IitineraryContext';
// import HotelsList from './pages/Hotels-list/HotelsList';
// import HotelNotFound from './pages/HotelNotFound';
// import Flights from './pages/Flights/Flights';
// import FlightsList from './pages/Flights/FlightsList';
import PageNotFound from './pages/PageNotFound';
import Explore from './pages/explore/Explore';
import Blogs from './pages/Blogs/Explore';
import ComingSoon from './pages/ComingSoon';
import { ThemeProvider } from '@mui/material/styles';
import theme from './vendors/Theme';
import AboutUs from './pages/AboutUs';
import ContactUs from './pages/ContactUs';
import LandingPage from './pages/Home Page/LandingPage';
import ExploreBlogs from './pages/explore/AllBlogs';
import React from 'react';


function App() {
  return (
    <>
      <Contexts>
        <ThemeProvider theme={theme}>
          <Router>
              <Routes>
                {/* <Route path="/home1" element={<Session><HomePage /></Session>} /> */}
                <Route path="/" element={<LandingPage />} />
                {/* <Route path="/flights" element={<Flights />} /> */}
                {/* <Route path="/flightslist" element={<FlightsList />} /> */}
                {/* <Route path="/hotelslist" element={<HotelsList />} /> */}
                <Route path="/explore/:location/:blogid" element={<Blogs />} />
                <Route path="/explore" element={<Explore />} />
                <Route path="/explore/:locationid" element={<ExploreBlogs />} />
                {/* <Route path="/itinerary" element={<IitineraryContext><Itinerary /></IitineraryContext>} /> */}
                <Route path="/privacy" element={<Privacy />} />
                <Route path="/cookie" element={<Cookies />} />
                {/* <Route path="/restpassword" element={<Resetpassword />} />
                <Route path="/confirmemail" element={<ConfirmEmail />} />
                <Route path="/verifyemail" element={<VerifyEmail />} />
                <Route path="/verifyresttoken" element={<Verificationforrest />} />
                <Route path="/signup" element={<AuthsContext><Signup /></AuthsContext>} />
                <Route path="/signin" element={<AuthsContext><Session><Signin /></Session></AuthsContext>} />
                <Route path="/forgotpassword" element={<Forgotpassword />} /> */}
                <Route path="/comingsoon" element={<ComingSoon />} />
                {/* <Route path="/hotelnotfound" element={<HotelNotFound />} /> */}
                <Route path="/about-us" element={<AboutUs />} />
                <Route path="/contact-us" element={<ContactUs />} />
                <Route path="*" element={<PageNotFound />} />
              </Routes>
          </Router>
        </ThemeProvider>
      </Contexts>
    </>
  );
}

export default App;
