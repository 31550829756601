const blogsData = [
    {
      id: 1,
      altText: `A stunning view of Sydney's skyline during sunset, with the sun casting a warm glow on the city's iconic buildings and landmarks.`,
      url_slug: 'sydney-budget-travel',
      location: "australia",
      url_cropped: "/assets/images/sydney_skyline_large_cropped.webp",
      title:'Sydney, Australia',
    },
    {
      id: 2,
      altText: `The majestic New York City skyline mirrored in the serene water, epitomizing its urban splendor.`,
      url_slug:'new-york-city-travel',
      location: "north america",
      url_cropped: "/assets/images/new_york_skyline_large_cropped.webp",
      title:'New York, USA',
    },
    {
      id: 3,
      altText: `Aerial view of Los Angeles skyline with majestic mountains in the backdrop.`,
      url_slug:'los-angeles-travel',
      location: "north america",
      url_cropped: "/assets/images/los_angeles_skyline_large_cropped.webp",
      title:'Los Angeles, USA',
    },
]
export default blogsData;