import { React, Suspense, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import "./scss/HomePage.css";
import Navbar from "../../components/ui-components/Navbar";
import Footer from "../../components/ui-components/Footer";
import ContentSection from "../../components/ui-components/ContentSection";

// trying

import { HashLink } from "react-router-hash-link/dist/react-router-hash-link.cjs.production";

export default function LandingPage() {
  let isLargeScreen = useMediaQuery("(min-width:992px)");
  let isMediumScreen = useMediaQuery("(min-width:768px)");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        container
        height={{ xs: "auto", md: "auto", lg: "100vh" }}
        sx={{
          backgroundImage: {
            xs: "none",
            md: `url('/assets/images/landing-page-blob.svg')`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "100% 0%",
            backgroundSize: "50%",
          },
        }}
      >
        <Grid item container xs={12}>
          <Navbar currentPage="landing-page" />
        </Grid>

        <Grid
          item
          container
          xs={12}
          marginTop="0"
          padding={{ xs: "0 5vw", md: "0 0 0 5vw", lg: "0 0 0 7vw" }}
          display="flex"
          alignItems={{ xs: "flex-start", md: "center", lg: "center" }}
          justifyContent="center"
        >
          <Grid item lg={6} md={6} xs={12} height="400px">
            <Typography
              className="heading"
              color="#181E4B"
              fontWeight="700"
              fontSize={{ xs: "40px", md: "38px", lg: "74px" }}
              lineHeight={{ xs: "normal", md: "normal", lg: "89px" }}
              letterSpacing={{ xs: "-1.6px", md: "-1.92px", lg: "-3.36px" }}
              width={{ xs: "auto", md: "auto", lg: "50vw" }}
              marginBottom={{ xs: "0", md: "16px", lg: "32px" }}
              mt={{ xs: "3vh", lg: "0px" }}
            >
              The Ultimate Trip <br />
              Planning Solution
            </Typography>
            <Typography
              paddingTop={{ xs: "2vh", lg: "1vh" }}
              marginBottom={{ xs: "16px", md: "16px", lg: "5vh" }}
              lineHeight={{ xs: "170%", md: "24px", lg: "30px" }}
              color="#5E6282"
              fontWeight="600"
              fontSize={{ xs: "16px", md: "16px", lg: "18px" }}
              width={{ xs: "auto", md: "400px", lg: "550px" }}
            >
              <b>Welcome to Catch U There</b> – the future of effortless travel
              planning. Get ready to simplify your trips with AI-driven
              itineraries, combining flights, hotels, and experiences in one
              easy search. Coming soon – your adventure starts here!
            </Typography>
            <HashLink smooth to="#subscribe">
              <Button
                variant="contained"
                sx={{
                  width: { xs: "100%", md: "auto", lg: "auto" },
                  bgcolor: "#6373CF",
                  textTransform: "none",
                  padding: "10px 30px",
                  color: "white",
                  fontSize: "18px",
                  fontWeight: "600",
                }}
              >
                Subscribe
              </Button>
            </HashLink>
          </Grid>
          <Grid
            item
            lg={6}
            md={6}
            xs={12}
            display="flex"
            marginTop="3vh"
            justifyContent="center"
            alignItems="center"
            position="relative"
            boxSizing="border-box"
            overflow="hidden"
          >
            <Box
              display={{ xs: "block", md: "none" }}
              position="absolute"
              zIndex="-1"
            >
              <img
                src="/assets/images/landing-page-blob.svg"
                width="100%"
                height="100%"
                loading="eager"
              />
            </Box>
            <picture
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <source
                media="(min-width:992px)"
                srcSet="/assets/images/lp_large.webp"
                width="646"
                height="764"
              />
              <source
                media="(min-width:768px)"
                srcSet="/assets/images/lp_medium.webp"
                width="349"
                height="400"
              />
              <img
                src="/assets/images/lp_small.webp"
                alt="A woman sitting on a suitcase with a backpack, representing travel and adventure."
                width="349"
                height="380"
                rel="preload"
                loading="eager"
              />
            </picture>
            {/* <BookingModel currentPage="home1" /> */}
          </Grid>
        </Grid>
      </Grid>

      {/*Rest of Body */}
      <Grid item xs={12}>
        <ContentSection currentPage="landing-page" />
      </Grid>
      {/* Footer */}
      <Grid item xs={12}>
        <Footer currentPage="landing-page" />
      </Grid>
    </Grid>
  );
}
